<template>
  <div>
    <span class="ml-2 text-warning" v-if="hasProductsByWeight()">
      <font-awesome-icon icon="balance-scale" /> Non pesato
    </span>
    <div v-else>
      <strong>{{ getPrice() }} </strong>
      <del class="d-block" v-if="priceHasBeenChanged()">{{
        getPriceOrig()
      }}</del>
    </div>
  </div>
</template>

<script>
import get from "lodash/get";
import { mapGetters } from "vuex";
import { formatPrice } from "../../../../../../helpers/common";

export default {
  name: "ProductsTablePrice",

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),
  },

  methods: {
    getPrice() {
      return formatPrice(this.data.price, this.currencySymbol).format();
    },

    getPriceOrig() {
      return formatPrice(
        get(this.data, "incident_price.orig_price", 0),
        this.currencySymbol
      ).format();
    },

    hasProductsByWeight() {
      return get(this.data, "need_w", 0) > 0;
    },

    priceHasBeenChanged() {
      return (
        get(this.data, "price") !== get(this.data, "incident_price.orig_price")
      );
    },
  },
};
</script>
